import React from "react";
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";


export const wrapPageElement = ({ element, props }) => {
 
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>
 
    {element}

    </GlobalProvider>
);

